<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Litsenziya malumotlari</h3>
            </div>
          </div>
          <div
            class="card-body d-flex flex-wrap"
            v-if="getLicensesById.truck_info !== undefined"
          >
            <div class="form-group col-4">
              <label>Reg raqami</label>
              <input
                type="text"
                :value="getLicensesById.reg_number"
                placeholder="Raqam"
                disabled
                class="form-control form-control-lg"
              />
            </div>
            <div
              class="form-group col-4"
              v-if="
                getLicensesById.licence_type !== null &&
                getLicensesById.licence_type !== undefined
              "
            >
              <label>Sertifikat turi</label>
              <input
                type="text"
                :value="getLicensesById.licence_type.name"
                placeholder="Raqam"
                disabled
                class="form-control form-control-lg"
              />
            </div>
            <div class="form-group col-4">
              <label>Berilgan sana</label>
              <input
                type="text"
                :value="getLicensesById.given | formatDate"
                placeholder="Raqam"
                disabled
                class="form-control form-control-lg"
              />
            </div>

            <div class="form-group col-4">
              <label>Boshlanish sanasi</label>
              <input
                type="text"
                :value="getLicensesById.start_date | formatDate"
                placeholder="Raqam"
                disabled
                class="form-control form-control-lg"
              />
            </div>

            <div class="form-group col-4">
              <label>Tugash sanasi</label>
              <input
                disabled
                type="text"
                :value="getLicensesById.expires | formatDate"
                placeholder="Raqam"
                class="form-control form-control-lg"
              />
            </div>
            <div class="form-group col-4">
              <label>Berilgan tashkilot nomi</label>
              <input
                type="text"
                disabled
                :value="getLicensesById.given_by"
                placeholder="Raqam"
                class="form-control form-control-lg"
              />
            </div>
            <div
              class="form-group col-4"
              v-if="getLicensesById.truck_info !== undefined"
            >
              <label>Mashina raqami</label>
              <input
                type="text"
                disabled
                :value="getLicensesById.truck_info.truck_number"
                placeholder="Raqam"
                class="form-control form-control-lg"
              />
            </div>
            <div
              class="form-group col-4"
              v-if="getLicensesById.truck_info !== undefined"
            >
              <label>Kuzov nomeri</label>
              <input
                type="text"
                disabled
                :value="getLicensesById.truck_info.kuzov_number"
                placeholder="Raqam"
                class="form-control form-control-lg"
              />
            </div>

            <div class="form-group col-4">
              <label>Kuzov turi</label>
              <input
                type="text"
                disabled
                :value="getLicensesById.truck_info.kuzov_type"
                placeholder="Raqam"
                class="form-control form-control-lg"
              />
            </div>

            <div class="form-group col-4">
              <label>Dvigatel raqami</label>
              <input
                type="text"
                disabled
                :value="getLicensesById.truck_info.dvigatel_number"
                placeholder="Raqam"
                class="form-control form-control-lg"
              />
            </div>

            <div class="form-group col-4">
              <label>Status</label>
              <input
                type="text"
                disabled
                :value="getLicensesById.truck_info.status"
                placeholder="Raqam"
                class="form-control form-control-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  created() {
    this.$store.dispatch('getLicensesById', this.$route.params.id)
  },
  computed: {
    getLicensesById() {
      return this.$store.state.requests.licensesById
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null && val !== undefined) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Transport' },

      { title: 'Hujjat malumotlari' },

      { title: 'Litsenziya' },
      { title: "Litsenziya Ma'lumotlari" }
    ])
  }
}
</script>

<style></style>
